import React, { useState } from "react";
import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import './EmailSignUp.css';


const EmailSubscription = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "subscribers"), {
        email: email,
      });
      setMessage("Successfully subscribed!");
    } catch (error) {
      setMessage("Failed to subscribe. Please try again.");
    }
  };

  return (
    <div className="EmailWrapper">
      <h2>Subscribe for FTX Pricing Updates</h2>
<form onSubmit={handleSubscribe}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Subscribe</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default EmailSubscription;
import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

export const firebaseConfig = {
  apiKey: "AIzaSyCriJA5PQ5C0FW3dEEe-lIivc5ViqCa_Gg",
  authDomain: "ftxclaimcalculator.com",
  databaseURL: "https://cherokeestoragesolutions-default-rtdb.firebaseio.com",
  projectId: "cherokeestoragesolutions",
  storageBucket: "cherokeestoragesolutions.appspot.com",
  messagingSenderId: "730520773929",
  appId: "1:730520773929:web:cc0e9c417167347501c338",
  measurementId: "G-MPJMCL8GZK"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app); // Realtime Database
const db = getFirestore(app); // Firestore

export { database, db, ref };
import React from 'react';
import SearchComponent from './components/SearchComponentMarch';
import Header from './components/Header';
import './App.css';

function App() {
  return (
    <div className="App">
            <Header />
      <SearchComponent />
      <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25393684' frameborder='0' width='100%' height='1000'></iframe>
    </div>
  );
}

export default App;
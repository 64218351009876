import React from 'react';
import './Header.css';
import EmailSubscription from './EmailSignUp';

const Header = () => {
    return (
      <header className="app-header">
        <aside className='LogoWrapper'>
          <a href="https://www.linkedin.com/company/cherokee-acquisition/" target="_blank" rel="noopener noreferrer">
            <img src="./linkedin.png" alt="Icon 3" />
          </a>
          <a href="https://www.youtube.com/watch?v=Bv8QvHg0jNc" target="_blank" rel="noopener noreferrer">
            <img src="./youtube.png" alt="Icon 2" />
          </a>
          <a href="https://twitter.com/claims_market" target="_blank" rel="noopener noreferrer">
            <img src="./X.png" alt="Icon 1" />
          </a>
        </aside>
        <div className="logo-container">
          <a className="logo" href="https://claims-market.com" target="_blank" rel="noopener noreferrer">
            <img src={'./ClaimsMarketLogo.png'} alt="Claims Market Logo" />
          </a>
        </div>
          <EmailSubscription />
        </header>
    );
};

export default Header;